html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content2 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  } 
   
  .container {
    flex: 1;
  }
  
  .footer {
    flex-shrink: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .has-overflow {
    overflow-y: auto;
  }
